import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify";
import Vuex from "vuex";
import router from './router/index'
import store from './store/index';
import appMixins from '@/mixins/globalJavascript'
import axios from 'axios';
import VImageInput from 'vuetify-image-input/a-la-carte';
import wysiwyg from 'vue-wysiwyg';
// import ScrollOutside  from 'vue-scroll-outside';

Vue.prototype.axios = axios;

Vue.component(VImageInput.name, VImageInput);

// Vue.config.productionTip = false

Vue.use(Vuex);

Vue.use(wysiwyg, {
  // maxHeight: "400px"
});

// Vue.directive('scroll-outside', ScrollOutside);

// create event bus
export const bus = new Vue

// init global mixins
Vue.mixin(appMixins)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created(){
    //renew jst every 10 min
    let renewjwt_interval = null; //eslint-disable-line
    // console.log(renewjwt_interval);


    this.$store.dispatch('refreshToken'); //cek token first

    renewjwt_interval = setInterval(()=>{  //then buat interval setiap 10 min utk refresh token

        this.$store.dispatch('refreshToken');  //this.$store.dispatch utk panggil globfunc, this.$store.getters utk panggil globvar

    }, 10 * 60 * 1000);
  },
}).$mount('#app')


