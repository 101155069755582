import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                
                // Replace
                primary: '#086098',
                secondary: '#63969D',
                success: "#54AD32",
                warning: "#FFC107",
                grey: "#757575",

                // New Class
                approve: "#4CAF50",
                pending: "#ff9800",
                reject: "#F44336",
                blackIcon: "#000000",
                blackk: "#000000",
                todayAnnc: "#3c4943",

                // Mobile
                primaryfit: '#086098',

                // For Calendar Car
                ssa2469a: "#FF0000",
                ssa2369a: "#00B0F0",
                vfp8523: "#44546A",
                ss6955w: "#4472C4",
                bme8395: "#A5A5A5",
                vp2514: "#FFC000",
                dda413: "#70AD47",
                pms8914: "#7030A0",
                sb5574b: "#ff2198",

                cardLeave: "#FFFFFF",
                




                // Default
                // primary: '#1976D2',
                // secondary: '#424242',
                // accent: "#82B1FF",
                // error: "#FF5252",
                // info: "#2196F3",
                // lightblue: "#14c6FF",
                // yellow: "#FFCF00",
                // pink: "#FF1976",
                // orange: "#FF8657",
                // magenta: "#C33AFC",
                // darkblue: "#1E2D56",
                // gray: "#909090",
                // neutralgray: "#9BA6C1",
                // green: "#2ED47A",
                // red: "#FF5c4E",
                // darkblueshade: "#308DC2",
                // lightgray: "#BDBDBD",
                // lightpink: "#FFCFE3",
                // white: "#FFFFFF"
            },
            dark: {
                
                primary: '#2196F3',
                secondary: '#32b4c5',

                // New Class
                approve: "#4CAF50",
                reject: "#F44336",
                pending: "#ff9800",
                blackIcon: "#FFFFFF",
                blackk: "#FFFFFF",
                darkTable: "#131313",
                todayAnnc: "#FFFFFF",

                // Mobile
                primaryfit: '#1777a7',

                // For Calendar Car
                ssa2469a: "#FF0000",
                ssa2369a: "#00B0F0",
                vfp8523: "#44546A",
                ss6955w: "#4472C4",
                bme8395: "#A5A5A5",
                vp2514: "#FFC000",
                dda413: "#70AD47",
                pms8914: "#7030A0",
                sb5574b: "#ff2198",

                cardLeave: "#1E1E1E",




                // Default
                // secondary: '#424242',
                // accent: '#82B1FF',
                // error: '#FF5252',
                // info: '#2196F3',
                // success: '#4CAF50',
                // warning: '#FFC107',
            },
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
