
import axios from 'axios'
import router from '@/router'

const regex = new RegExp('redirecturl');

export default {
    state: {
        user: '',
        auth: {
            token: 'null',
        },
        loading: false,
        error: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
    },
    actions: {
        setUser({ commit }, payload) {
            commit('setUser', payload)
        },
        signUserIn({ commit }, payload) {
            commit('setLoading', true)
            axios
                .post(process.env.VUE_APP_API_URL + `employees/login`, payload)
                .then((response) => {                    
                    commit('setLoading', false)
                    // const newUser = payload
                    // console.log(newUser);
                    commit('setUser', response.data)
                    localStorage.setItem('user', JSON.stringify(response.data))
                    setCookie('mytoken', response.data.token)
                    if (regex.test(location.href)) {
                        let result = location.href.substr(location.href.lastIndexOf("serialno"));
                        router.push('/qrCode?' + result)
                    } else {
                        router.push('/')
                    }
                })
                .catch((error) => {
                    commit('setLoading', false)
                    commit('setError', error.response.data)
                })
        },
        signOut({ commit }) {
            localStorage.clear()
            commit('setUser', null)
            commit('setError', null)
            // alert('wrong username/password')
            resetCookie()
            router.push('/login').catch(()=>{});
        },
        checkToken({ commit }) {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                user.token = getCookie('mytoken')   //cookie simpan latest token
                commit('setUser', user)
                //user.token = null
                //localStorage.setItem('user', JSON.stringify(user))
            }
        },
        clearError({ commit }) {
            commit('clearError')
        },
        refreshToken({ state }) {
            return new Promise((resolve, reject) => {

                if(!state.user || !state.user.token){ 
                    console.log('no token found'); 
                    return false; 
                }
                else {
                    axios
                    .post(process.env.VUE_APP_API_URL + `employees/renewjwt`, null, {  //renewjwt xperlu body, only header
                        headers: {
                            Authorization: 'Bearer ' + state.user.token,
                        },
                    })
                    .then((response) => {
                        // update token on user state
                        // update token on localstorage
                        console.log('token renew at '+new Date());
                        state.user.token = response.data.newjwt;  //get new jwt n put it in state
                        window._mycurrentjwt = state.user.token;  //_mycurrentjwt just for debug
                        setCookie('mytoken', response.data.newjwt)
                        resolve(response)
                    })
                    .catch((error) => {
                        // set user on state to null
                        // clear local storage
                        // goto login page
                        localStorage.clear()
                        resetCookie()
                        router.push('/login')  //kene login
                        reject(error)
                    })
                }

            })
        },
        // async loadData({ commit }) {
        //     let response = await Api().get("/cpu"); //eslint-disable-line
        //     commit('SET_DATA', response.data.dates);
        // }
    },
    getters: {
        user(state) {
            return state.user
        },
        token(state) {
            return state.user.token
        },
        error(state) {
            return state.error
        },
        loading(state) {
            return state.loading
        },
        userRole(state) {
            return state.user.userAccessName
        },

    },
}


function setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (30 * 60 * 1000));  //30min
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
    }
    }
    return "";
}


function resetCookie(){
    setCookie("mytoken", "", 365);
    // setCookie("name", "", 365);
    // setCookie("address", "", 365);
    // setCookie("ngoType", "", 365);
    // setCookie("phone", "", 365);
    // setCookie("token", "", 365);
    // setCookie("coverage", "", 365);
    //alert('cookies cleared');
}