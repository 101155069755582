import store from '@/store'

export default (to, from, next) => {
    //store.dispatch('checkToken')  //ni punca. dia gi tarik token yg lama kat localstorage plak eveitime user click mane2 route
    // if (store.getters.user) {
    //     next()
    // } else {
    //     next('/login').catch(()=>{})
    //     // next()
    // }
    if (localStorage.user) {
        store.dispatch('checkToken')
        next()
    } else {
        next('/login').catch(()=>{})
        //next()
    }
}
