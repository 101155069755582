import Vue from 'vue'
// import App from './App.vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'

Vue.use(VueRouter)

export default new VueRouter({
    mode: "history",
    routes: [

        // !!! Testing !!!
        // { 
        //     path: "/login2",
        //     name: "Login2",
        //     component: () => import('@/views/User/Login/Login2'),
        //     // meta: { showLeftPanel: true },
        // },


        // ===== User/Login =====
        { 
            path: "/login",
            name: "Login",
            component: () => import('@/views/User/Login/Login'),
            // meta: { showFooter: true, showLeftPanel: false },
        },


        // ===== Announcement =====
        { 
            path: "/",
            name: "Announcement",
            component: () => import('@/views/Announcement/Announcement'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
    


        // ===== Dashboard =====
        { 
            path: "/dashboard",
            name: "Dashboard",
            component: () => import('@/views/Dashboard/Dashboard'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
    


        // ===== Profile =====
        { 
            path: "/profile",
            name: "Profile",
            component: () => import('@/views/Profile/Profile'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },


        // ===== Leave =====
        { 
            path: "/leave",
            name: "Leave",
            component: () => import('@/views/Leave/Leave'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },


        // ===== Car Booking =====
        { 
            path: "/carBooking",
            name: "Car Booking",
            component: () => import('@/views/CarBooking/CarBooking'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },


        // ===== Meeting Room =====
        { 
            path: "/meetingRoom",
            name: "Meeting Room",
            component: () => import('@/views/MeetingRoom/MeetingRoom'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },


        // ===== Asset Inventory =====
        { 
            path: "/assetInventory",
            name: "Asset Inventory",
            component: () => import('@/views/AssetInventory/Inventory'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
    

        // ===== System Admin =====
        { 
            path: "/systemAdmin-announcement",
            name: "System Admin - Announcement",
            component: () => import('@/views/SystemAdmin/Announcement'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        { 
            path: "/systemAdmin-stafflist",
            name: "System Admin - Staff List",
            component: () => import('@/views/SystemAdmin/StaffList'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        { 
            path: "/systemAdmin-leave",
            name: "System Admin - Leave",
            component: () => import('@/views/SystemAdmin/Leave'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        { 
            path: "/systemAdmin-carBooking",
            name: "System Admin - Car Booking",
            component: () => import('@/views/SystemAdmin/CarBooking'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        { 
            path: "/systemAdmin-meetingRoom",
            name: "System Admin - Meeting Room",
            component: () => import('@/views/SystemAdmin/MeetingRoom'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        { 
            path: "/systemAdmin-inventory",
            name: "System Admin - Inventory",
            component: () => import('@/views/SystemAdmin/Inventory'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        { 
            path: "/systemAdmin-setting",
            name: "System Admin - Setting",
            component: () => import('@/views/SystemAdmin/Setting'),
            meta: { showMobileHeader: true, showLeftPanel: true },
            beforeEnter: AuthGuard
        },
        // ===== QR Code =====
        { 
            path: "/qrCode",
            name: "QR Code - Inventory Details",
            component: () => import('@/views/QRCode/QrCode'),
            meta: { showMobileHeader: false, showLeftPanel: false },
            //beforeEnter: AuthGuard
        },
    ]
})

