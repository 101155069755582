<template>
    <v-container
    v-click-outside="onClickOutside"
    v-scroll="onScroll"
    v-scroll:#scroll-target="onScroll"
    class="container-header-mobile"
    v-if="$vuetify.breakpoint.mdAndDown"
    >
        <v-app-bar
          fixed 
          elevate-on-scroll
          class="primaryfit"
          dark
        >
            <v-list-item-avatar link @click="openSummaryProfile">
                <v-img :src="this.profileImageUrl"></v-img>
            </v-list-item-avatar>
            <div dense class="display-userText-header">
                <h4 dense link @click="openSummaryProfile" v-text="this.UserDisplay(myNameMobile)"></h4>
            </div>
            
            <v-spacer></v-spacer>
            

            <!-- Notification Button -->
            <v-btn
            icon
            v-if="onlyAdmin || onlyCarManager || onlyRoomManager || adaKuli && this.userDahUpdate == 'yes'"
            @click="goToPageNotify()"
            >
                <v-badge
                v-if="countNotifyMobile != null"
                color="red"
                :content="countNotifyMobile"
                >
                    <v-icon dense>mdi-bell-ring</v-icon>
                </v-badge>
                <v-icon dense v-if="countNotifyMobile == null">mdi-bell</v-icon>    
            </v-btn>

            <!-- v-if="onlyAdmin || onlyModerator || onlyHR || onlyInfra || onlyCarManager || onlyRoomManager" -->
            <v-btn
            icon
            @click="toggle_dark_mobileDarkMode"
            >
                <v-icon v-text="valueIcon"></v-icon>
            </v-btn>

            <!-- Logout button for admin level -->
            <v-btn
            icon
            v-if="onlyAdmin || onlyModerator || onlyHR || onlyInfra || onlyCarManager || onlyRoomManager || this.userDahUpdate == 'no'"
            @click="confirmationLogout"
            >
                <v-icon>mdi-logout-variant</v-icon>
            </v-btn>

            <!-- Main Menu Button -->
            <v-btn
            v-if="this.userDahUpdate == 'yes'"
            icon
            @click="expand = !expand"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            

        </v-app-bar>

        
        <v-expand-transition
        >
            <!-- </v-sheet> -->
            <v-footer
            fixed 
            elevate-on-scroll
            scroll-target="#scrolling-techniques-7"
            dense
            v-show="expand"
            height="212.5"
            class="menu-mobile-mems"
            dark
            >
            <!-- <v-card src="@/assets/img/others/mobile-menu3.jpg"> -->

                    <v-row class="px-10" v-if="$vuetify.breakpoint.mdAndDown">
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-bullhorn</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Announcement</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/dashboard'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-view-dashboard-outline</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Dashboard</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/profile'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-account</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Profile</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/leave'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-calendar-cursor</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Leave</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/carBooking'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-car</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Car Booking</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/meetingRoom'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-forum-outline</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Meeting Room</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="goToPage('/assetInventory'); expand = false;"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-laptop</v-icon>
                            </v-btn>
                            <span class="menuText-mobile">Asset Inventory</span>
                        </v-col>
                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        @click="openListMenuAdmin"
                        v-if="onlyAdmin || onlyModerator || onlyHR || onlyInfra || onlyCarManager || onlyRoomManager"
                        >
                            <v-btn
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-account-wrench</v-icon>
                            </v-btn>
                            <span
                            class="menuText-mobile"
                            >
                                Admin
                            </span>
                        </v-col>

                        <v-col
                        class="d-flex flex-column justify-center align-center"
                        cols="3"
                        v-if="onlyNormalUser"
                        @click="confirmationLogout"
                        >

                            <v-btn
                            v-if="onlyNormalUser"
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon>mdi-logout-variant</v-icon>
                            </v-btn>
                            <span
                            class="menuText-mobile"
                            >
                                Logout
                            </span>

                            <!-- <v-btn
                            v-if="onlyNormalUser"
                            icon
                            height="40"
                            width="40"
                            >
                                <v-icon v-text="valueIcon"></v-icon>
                            </v-btn>
                            <span
                            class="menuText-mobile"
                            v-text="valueText"
                            >
                            </span> -->

                        </v-col>

                    </v-row>

            <!-- </v-card> -->
            </v-footer>
            
        </v-expand-transition>



        <!-- List Menu Admin -->
        <template>
            <v-dialog
            v-model="dialogMenuAdmin"
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5 primary white--text">
                <v-icon class="icon-text white--text">mdi-account-wrench</v-icon>Administrator
                </v-card-title>

                
                <!-- Content -->
                <template>
                <v-card
                    class="mx-auto"
                    max-width="300"
                    tile
                >
                    <v-list dense>
                    <!-- <v-subheader>REPORTS</v-subheader> -->
                    <v-list-item-group color="primary" v-model="ruleGroup">

                        <!-- <v-list-item
                        v-for="(item, i) in childAdmin"
                        :key="i"
                        @click="goToPage(item.url)"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->

                        <v-list-item
                        v-if="onlyAdmin || onlyHR"
                        to="/systemAdmin-announcement"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-bullhorn-variant</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Announcement</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyHR || onlyModerator"
                        to="/systemAdmin-stafflist"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-group</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Staff List</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                        v-if="onlyAdmin || onlyHR"
                        to="/systemAdmin-leave"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-calendar-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Leave</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                        v-if="onlyAdmin || onlyCarManager"
                        to="/systemAdmin-carBooking"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-car-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Car Booking</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                        v-if="onlyAdmin || onlyRoomManager"
                        to="/systemAdmin-meetingRoom"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-forum</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Meeting Room</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                        v-if="onlyAdmin || onlyInfra"
                        to="/systemAdmin-inventory"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-desktop-classic</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Inventory</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- <v-list-item
                        v-if="onlyAdmin"
                        to="/systemAdmin-setting"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Setting</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                        
                    </v-list-item-group>
                    </v-list>
                </v-card>
                </template>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="clearRule"
                    >
                        Close
                    </v-btn>
                </v-card-actions>


            </v-card>
            </v-dialog>
        </template>



        <!-- Logout Confirmation Popup -->
        <template>
            <v-dialog
            v-model="dialogLogout"
            max-width="290"
            >
            <v-card>
                <v-card-title class="text-h5">
                <v-icon class="icon-text blackIcon--text">mdi-logout</v-icon>Logout
                </v-card-title>

                <v-card-text class="blackIcon--text">
                Are you sure you want to Logout?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogLogout = false"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="signOut"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template>



        <!-- Display Summary User -->
        <template>
        <div class="text-center">
            <v-dialog
            v-model="dialogSummaryProfile"
            width="344"
            height="500"
            >
                <v-card
                    class="mx-auto "
                    max-width="344"
                >
                    
                    <div
                    height="265px"
                    class="d-flex flex-column justify-center align-center pa-10 header-summaryCardProfile"
                    align="center"
                    >

                        <v-badge
                        bordered
                        overlap
                        offset-x="65"
                        offset-y="200"
                        class="custom-badge-btn"
                        >
                            <v-btn class="primary--text btn-cam-profile" color="white" fab small slot="badge" @click.native="editImageProfile">  
                                <v-icon dense >mdi-camera</v-icon>
                            </v-btn>
                            
                            <v-avatar size="250">
                                <v-img :src="this.profileImageUrl" ></v-img>
                            </v-avatar>
                        </v-badge>
                        
                    </div>
                    
                    <!-- align="center" class="d-flex flex-column justify-center align-center" -->


                    <v-card-title  v-text="this.UserDisplay(this.$store.getters.user.name)"></v-card-title>

                    <v-card-subtitle v-if="this.$store.getters.user.position != null || this.$store.getters.user.position != ''" v-text="this.$store.getters.user.position">
                    </v-card-subtitle>
                    <v-card-subtitle v-if="this.$store.getters.user.position == null || this.$store.getters.user.position == ''">
                        (No Position Title)
                    </v-card-subtitle>

                    <v-card-text class="pb-0" style="display:flex;">
                        <v-container>
                            <v-row >
                                <p class="mr-5 mb-1"><v-icon class="icon-text" small>mdi-phone</v-icon><span v-text="this.$store.getters.user.phone"></span></p>
                            </v-row>
                            <v-row>
                                <p><v-icon class="icon-text" small>mdi-email</v-icon><span v-text="this.$store.getters.user.email1"></span></p>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="white"
                            class="primary"
                            icon
                            @click="goToPage('/profile');dialogSummaryProfile = false;"
                        >
                            <v-icon>mdi-account-details</v-icon>
                        </v-btn>
                        <!-- <v-btn
                            color="white"
                            class="primary"
                            icon
                            @click="editImageProfile"
                        >
                            <v-icon>mdi-camera</v-icon>
                        </v-btn> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        </template>

        <!-- Popup Edit Profile Image -->
        <template>
                <v-dialog
                v-model="dialogEditImage"
                persistent
                >
                    <v-card height="auto" width="auto">

                        <v-card-title class="title-bar-modul">
                            <span class="text-h5">Upload New Profile Image</span>
                        </v-card-title>

                        <v-card-text class="pb-0">

                            <span class="blackk--text">
                                <span class="red--text text-bold">**Note:</span><br>
                                 <span class="text-bold">IOS</span> capturing image is in HEIC format which is <b>not supported</b> by standard browser.
                                 We recommend to use standard picture format (PNG/JPEG).
                            </span>
                        
                            <v-image-input
                            class="mt-3"
                            v-model="viewImageProfile"
                            :image-quality="0.85"
                            clearable
                            image-format="jpeg"
                            uploadIcon="mdi-upload"
                            fullWidth
                            overlayBorderWidth
                            @file-info="onFileInfo"
                            />

                            <small class="text-bold">
                                <span class="red--text">**</span>
                                Click on box above to upload new profile picture.
                            </small>
                        
                        </v-card-text>

                        <v-card-actions class="pb-5">
                            <v-btn
                                :disabled="viewImageProfile == null"
                                class="primary icon-text"
                                @click="uploadImageProfile"
                            >
                                <v-icon>mdi-image-area-close</v-icon> Update
                            </v-btn>
                            <v-btn
                                class="primary icon-text"
                                @click="dialogEditImage = false; viewImageProfile = null;"
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-dialog>
        </template>

        <!-- Success Upload -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccessUploadImg"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5" color="green">
                        <v-icon class="icon-text" color="success">mdi-check-circle</v-icon>
                        Success
                    </v-card-title>
                    <v-card-text class="blackIcon--text">Upload New Profile Image Success</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogEditImage = false; dialogSuccessUploadImg = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Failed Success -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogFailedUploadImg"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5" color="green">
                        <v-icon class="icon-text" color="red">mdi-alert-circle</v-icon>
                        Failed
                    </v-card-title>
                    <v-card-text class="blackk--text">Upload New Profile Image Failed. Please try again or directly contact admin.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogFailedUploadImg = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Dialog User Degil -->
        <template>
          <v-row justify="center">
            <v-dialog
              v-model="dialogReminderProfile"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar
                  dark
                  color="primary"
                >
                  <!-- <v-btn
                    icon
                    dark
                    @click="dialogReminderProfile = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                  <v-toolbar-title>
                      <!-- <v-icon>mdi-alert</v-icon> -->
                      REMINDER
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                    <!-- <v-btn
                    icon
                    dark
                    @click="dialogReminderProfile = false"
                    >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                </v-toolbar>
                <v-list
                  three-line
                  subheader
                >
                  <v-list-item>
                    <v-list-item-content>
                      <h1 class="h1 primary--text mt-2">
                          <v-icon color="primary" large>mdi-alert</v-icon>
                          GENTLY REMINDER
                      </h1>
                      <v-list-item-subtitle class="blackk--text mt-2">In order to start using MEMS functions, you need to update Profile Details, Profile Picture and Emergency Contact.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                
                <!-- <v-container>
                    <v-expansion-panels class="ma-5">
                        <v-expansion-panel>

                            <v-expansion-panel-header>
                                <strong>
                                    <v-icon class="mr-2">mdi-account-circle</v-icon>
                                    Update Profile Picture
                                </strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-carousel
                                width="100"
                                height="500"
                                v-model="modelSlideEditProfileImg"
                                >
                                    <v-carousel-item
                                    v-for="(item, i) in remindProfileImg"
                                    :key="i"
                                    >
                                        <v-container
                                        fill-height
                                        fluid
                                        pa-0 ma-0 pb-0 
                                        >
                                            
                                            <v-layout fill-height align-end pb-0 mb-0>
                                                <v-flex xs12>
                                                    <v-img height="500" :src="item.picture"></v-img>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <strong>
                                    <v-icon class="mr-2">mdi-account-details</v-icon>
                                    Update Profile Details & Emergency Contact
                                </strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-carousel
                                width="100"
                                height="500"
                                v-model="modelSlideEditProfileDetails"
                                >
                                    <v-carousel-item
                                    v-for="(item, i) in remindProfileDetails"
                                    :key="i"
                                    >
                                        <v-container
                                        fill-height
                                        fluid
                                        pa-0 ma-0 pb-0 
                                        >
                                            
                                            <v-layout fill-height align-end pb-0 mb-0>
                                                <v-flex xs12>
                                                    <v-img height="500" :src="item.picture"></v-img>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-container> -->

                <v-btn
                    color="primary"
                    class="ml-5 mt-2"
                    @click="dialogReminderProfile = false"
                    >
                        Yes, Understand
                    </v-btn>

              </v-card>
            </v-dialog>
          </v-row>
        </template>


    </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';
import VImageInput from 'vuetify-image-input/a-la-carte';

export default {
    components: {
        'v-image-input': VImageInput,
    },
    data: () => ({

        dialogReminderProfile: false,
        remindProfileImg: [
            { picture: require("@/assets/img/caraUpdate/aa.png")},
            { picture: require("@/assets/img/caraUpdate/bb.png")},
            { picture: require("@/assets/img/caraUpdate/cc.png")},
            { picture: require("@/assets/img/caraUpdate/dd.png")},
            { picture: require("@/assets/img/caraUpdate/ee.png")},
            { picture: require("@/assets/img/caraUpdate/ff.png")},
            // { picture: require("@/assets/img/caraUpdate/g.png")},
        ],
        modelSlideEditProfileImg: false,
        remindProfileDetails: [
            { picture: require("@/assets/img/caraUpdate/11.png")},
            { picture: require("@/assets/img/caraUpdate/22.png")},
        ],
        modelSlideEditProfileDetails: false,


        valueIcon: "mdi-weather-sunny",
        ruleGroup: null,
        expand: false,
        value: 0,
        dialogMenuAdmin: false,
        childAdmin: [
            { text:'Announcement' , icon:"mdi-bullhorn-variant", url: "/systemAdmin-announcement"},
            { text:'Staff List' , icon:"mdi-account-group", url: "/systemAdmin-stafflist"},
            { text:'Leave' , icon:"mdi-calendar-edit", url: "/systemAdmin-leave"},
            { text:'Car Booking' , icon:"mdi-car-cog", url: "/systemAdmin-carBooking"},
            { text:'Meeting Room' , icon:"mdi-forum", url: "/systemAdmin-meetingRoom"},
            { text:'Inventory' , icon:"mdi-desktop-classic", url: "/systemAdmin-inventory"},
            { text:'Setting' , icon:"mdi-account-cog", url: "systemAdmin-setting"},
        ],
        dialogLogout: false,
        valueText: "Light Mode",

        dialogSummaryProfile: false,
        dialogEditImage: false,

        urlImage: "",
        viewImageProfile: null,
        detailsDataImage: null,

        profileImageUrl: null,
        dialogSuccessUploadImg: false,
        dialogFailedUploadImg: false,

        blankProfile: "https://apis.spatialworks.com.my/publicdir/2022/01/20/noprofil.png",

        myNameMobile: '',
        myPositionMobile: ''
    }),
    methods: {

        loadProfileImage(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=3&id='+this.$store.getters.user.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                // this.profileImageUrl = response.data[0].picture;


                // Condition for first time user with total null data
                if (response.data.info == null || response.data.info == "") {
                    this.profileImageUrl = this.blankProfile;
                }
                else{
                    if (response.data.info.picture == "" || response.data.info.picture == null) {
                        this.profileImageUrl = this.blankProfile;
                    }
                    else{
                        this.profileImageUrl = response.data.info.picture; 
                    }
                }

                
                


            })
            .catch(error => {
                console.log(error.response);
            })

            // let myInterval = setInterval(() => {
            //     this.profileImageUrl = this.urlImage;
            //     clearInterval(myInterval);
            // }, 1000);

        },

        toggle_dark_mobileDarkMode(){
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

            if(this.valueIcon == "mdi-weather-sunny"){
                this.valueIcon = "mdi-weather-night";
                this.valueText = "Dark Mode";
                let element = document.querySelectorAll(".title-bar-modul")
                element.forEach(function(res){
                    res.classList.add("darkMode");
                });
            }
            else if(this.valueIcon == "mdi-weather-night"){
                this.valueIcon = "mdi-weather-sunny";
                this.valueText = "Light Mode";
                let element = document.querySelectorAll(".title-bar-modul")
                element.forEach(function(res){
                    res.classList.remove("darkMode");
                });
            }
        },

        signOut() {
            this.$store.dispatch('signOut');
            this.$vuetify.theme.dark = false;
        },

        load(){

        },

        onClickOutside() {
            this.expand = false;
        },

        onScroll () {
            this.expand = false;
        },

        openSummaryProfile(){
            this.dialogSummaryProfile = true;
        },

        openListMenuAdmin(){
            this.dialogMenuAdmin = true;
            this.SelectedRule ={}
            this.ruleGroup = null;
        },

        confirmationLogout(){
            this.dialogLogout = true;
        },

        editImageProfile(){
            this.dialogEditImage = true;
        },

        onFileInfo(image){
            // console.log(image);
            this.detailsDataImage = image;
        },

        uploadImageProfile(){
            const dataURLtoFile = (dataurl, filename) => {
                const arr = dataurl.split(',')
                const mime = arr[0].match(/:(.*?);/)[1]
                const bstr = window.atob(arr[1])
                let n = bstr.length
                const u8arr = new Uint8Array(n)
                while (n) {
                    u8arr[n-1] = bstr.charCodeAt(n-1)
                    n -= 1 // to make eslint happy
                }
                return new File([u8arr], filename, { type: mime })
            }

            // generate file from base64 string
            const file = dataURLtoFile(this.viewImageProfile.toString(),this.detailsDataImage.name)
            // put file into form data
            const data = new FormData()
            data.append('files', file, file.name)

            axios.post(
                process.env.VUE_APP_API_URL + "employees/upload?profilpic=1",
                data,
                {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.getters.user.token,
                        "Content-Type": "multipart/form-data",
                    }
                }
            )
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.loadProfileImage();
                this.dialogSuccessUploadImg = true;
                this.checkUserDegil();
            })
            .catch(e => {
                console.log(e.response);
                this.dialogFailedUploadImg = true;
            })
        },

        loadMySummaryDetailsMobile(){

            this.myNameMobile = '';

            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data;

                for(let i in data){
                    if (data[i].idemp == this.$store.getters.user.idEmp) {
                        this.myNameMobile = data[i].name;
                    }
                }

            })
            .catch(error => { //eslint-disable-line
                console.log(error.response);
            })

        },

        clearRule(){
            this.SelectedRule ={}
            this.ruleGroup = null;
            this.dialogMenuAdmin = false;
        },

        // closeDialog() {
        // this.$emit(this.expand, false);
        // },

        checkUserDegil(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                // console.log(this.$store.getters.user);

                for(let i in data){
                    
                    if (data[i].idemp == this.$store.getters.user.idEmp) {

                        // console.log(data[i]);

                        if (data[i].phone == "") {
                            this.userDahUpdate = "no";
                            this.goToPage("/profile");
                            this.dialogReminderProfile = true;
                        }
                        else if (data[i].phone == null) {
                            this.userDahUpdate = "no";
                            this.goToPage("/profile");
                            this.dialogReminderProfile = true;
                        }
                        else{
                            if (data[i].picture == "") {
                                this.userDahUpdate = "no";
                                this.goToPage("/profile");
                                this.dialogReminderProfile = true;
                            }
                            else if (data[i].picture == null) {
                                this.userDahUpdate = "no";
                                this.goToPage("/profile");
                                this.dialogReminderProfile = true;
                            }
                            else{
                                this.userDahUpdate = "yes";
                                this.dialogReminderProfile = false;
                            }
                            
                        }
                    }

                }
                
            })
            .catch(error => {
                // console.log(error);
                console.log(error.response);
            })
        }

    },
    mounted(){
        this.loadNotifyLeave();
        this.loadNotifyCar();
        this.loadNotifyRoom();

        this.loadProfileImage();
        this.loadMySummaryDetailsMobile();
        this.checkUserDegil();
        // this.$store.getters.user.name = this.capitalizeWords(this.$store.getters.user.name)
    },
    watch: {
        ruleGroup: function () {
            if (this.ruleGroup==null) this.clearRule()
        }
    },
    computed: {
        countNotifyMobile(){
            
            let count = null;
            let leave = null;
            let car = null;
            let meetingRoom = null;

            if (this.totalPendingLeave != null) {
                leave = this.totalPendingLeave;
            }
            else{
                leave = 0;
            }

            if (this.totalPendingCar != null) {
                car = this.totalPendingCar;
            }
            else{
                car = 0;
            }

            if (this.totalPendingMeetingRoom != null) {
                meetingRoom = this.totalPendingMeetingRoom;
            }
            else{
                meetingRoom = 0;
            }


            if (count != 0) {
                if (this.$store.getters.userRole === 'ADMIN') {
                    count = leave + car + meetingRoom;
                    if (count > 0) {
                        return count;
                    }
                    else{
                        return null;
                    } 
                }
                if (this.$store.getters.userRole === 'NORMAL USER') {
                    if (this.$store.getters.user.kuli === true) {
                        if (leave > 0) {
                            count = leave;
                            return count;
                        }
                        else{
                            return null;
                        } 
                    }
                    else{
                        return null;
                    }
                }
                if (this.$store.getters.userRole === 'MODERATOR') {
                    if (this.$store.getters.user.kuli === true) {
                        if (leave > 0) {
                            count = leave;
                            return count;
                        }
                        else{
                            return null;
                        } 
                    }
                    else{
                        return null;
                    }
                    // if (leave > 0) {
                    //     count = leave;
                    //     return count;
                    // }
                    // else{
                    //     return null;
                    // } 
                }
                if (this.$store.getters.userRole === 'CAR MANAGER') {
                    if (car > 0) {
                        count = car;
                        return count;
                    }
                    else{
                        return null;
                    } 
                }
                if (this.$store.getters.userRole === 'MEETING ROOM') {
                    if (meetingRoom > 0) {
                        count = meetingRoom;
                        return count;
                    }
                    else{
                        return null;
                    } 
                }
                else{
                    return null;
                }
            }
            else{
                return null;
            }
            
        },
    },
    created (){
        bus.$on('changeIt', (data) => {
            
            // console.log(data);

            if (data == "loadNotify") {
                this.loadNotifyLeave();
                this.loadNotifyCar();
                this.loadNotifyRoom();
            }
            else if (data == "openDP") {
                this.dialogSummaryProfile = true;
            }
            else if (data == "loadProfile") {
                this.loadMySummaryDetailsMobile();
                this.checkUserDegil();
            }

           
        })
    }
}
</script>


<style>

::v-deep .v-btn {
  padding-left: 12px;
  padding-right: 12px;
}

    .container-header-mobile{
        padding: 0px !important;
    }

    .menu-mobile-mems{
        background-image: url("~@/assets/img/others/mobile-menu3.jpg") !important;
        background-size: cover !important;
    }

    .menu-mobile-mems>.v-toolbar__content{
        display: unset !important;
    }

    .closeIcon-sumProfile{
        z-index: 5 !important;
        position: absolute !important;
    }


    .menuText-mobile{
        font-size: 0.7em !important;
        text-align: center !important;
    }

    .display-userText-header{
        padding: 5px 0px;
    }

    /* .display-userText-header > span{
        font-size: 0.8em !important;
    } */

    /* v-expand-x-transition */

    .sticky {
        position: sticky !important;
        top: 0;
        z-index: 2;
    }

</style>