<template>
  <v-app id="app">
    

    
    
    <!-- Left Panel -->
    <left-panel
    v-if="$route.meta.showLeftPanel"
    ></left-panel>
    
    <v-main dark class="main-cust">
      
      <!-- Mobile Header -->
      <mobile-header v-if="$route.meta.showMobileHeader"></mobile-header>

      <!-- <v-card class="ma-5 pa-0" tile> -->
        <router-view :key="$route.path" v-if="$vuetify.breakpoint.lgAndUp"></router-view>
      
        <router-view :key="$route.path" class="mt-5" v-if="$vuetify.breakpoint.mdAndDown"></router-view>
      <!-- </v-card> -->
      
      <!-- <div class="hidden-footer">
        <template>
          <v-container>
            Hidden Footer
          </v-container>
        </template>
      </div> -->
    </v-main>

    


  </v-app>
</template>

<script>
import MobileHeader from '@/components/MobileHeader'
import LeftPanel from '@/components/LeftPanel'
export default {
  name: 'App',
  components: {
    'mobile-header': MobileHeader,
    'left-panel': LeftPanel
  },
  data: () => ({
    // hover: false
  }),
  mounted(){
    // this.$store.getters.user.name = this.capitalizeWords(this.$store.getters.user.name)
    // console.log(this.$store.getters.user);
  },
  methods:{

    
  }
}
</script>

<style lang="scss">
@import '~scss/main';

#app{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// #app > div:nth-child(1) {
  // display: contents; 
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: contents !important;
  text-align: center;t
  color: #2c3e50;
  margin-top: 60px; */
// }

// .main-container{
//     width: -webkit-fill-available;
//     padding: 0px !important;
//     display: block !important;
//     width: auto !important;
// }

// .main-container > .v-main__wrap{
//     display: contents !important;
// }

// .hidden-footer > div{
//   color: #ffffff00;
//   height: 150px;
// }

// .v-application--wrap {
//     display: contents !important;
// }


</style>
