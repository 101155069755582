<template>

    <v-container
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    v-if="$vuetify.breakpoint.lgAndUp"
    fluid
    class="d-flex flex-row align-start pa-0 align-stretch left-panel-content"
    >
        
        <!-- expand-on-hover
        permanent
        :mini-variant.sync="mini"
         -->
        <v-navigation-drawer
        class="nav-left-panel"
        clipped
        app
        hide-overlay
        persistent
        :mini-variant="mini"
        fixed
        >


            <v-list class="header-left-panel"
            nav
            dense
            >
                <!-- <v-list-item class="px-2" link @click="openSummaryProfile">  Uncomment yang ni kalau guna mini left panel -->
                <v-list-item class="px-4" link @click="openSummaryProfile">
                    <v-list-item-avatar size="60"> <!-- Buang attribute Size 60 kalau guna mini left panel -->
                        <v-img :src="this.profileImageUrl"></v-img>
                    </v-list-item-avatar>
                </v-list-item>

                <v-list-item link @click="openSummaryProfile">
                    <v-list-item-content>
                    <v-list-item-title v-text="this.UserDisplay(myName)" class="text-h6 white--text">
                        
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="this.$store.getters.user.position != null || this.$store.getters.user.position != ''" class="white--text text-upperCase" v-text="this.$store.getters.user.position">
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="this.$store.getters.user.position == null || this.$store.getters.user.position == ''" class="white--text text-upperCase">
                        (No Position Title)
                    </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <!-- <v-divider></v-divider> -->

            <v-list
            v-if="onlyAdmin || onlyCarManager || onlyRoomManager || adaKuli && this.userDahUpdate == 'yes'"
            nav
            dense
            >
                <v-list-item
                v-for="(item, index) in noTi"
                :key="index"
                link
                class="btn-general-menu"
                @click="goToPageNotify()"
                >
                    <v-list-item-icon>
                        <v-badge
                        v-if="countNotify != null"
                        color="red"
                        :content="countNotify"
                        >
                            <v-icon color="primary">mdi-bell-ring</v-icon>
                        </v-badge>

                        <v-icon v-if="countNotify == null">mdi-bell</v-icon>
                    
                    </v-list-item-icon>

                    <v-list-item-title
                    :class="countNotify == null? '' : 'text-bold primary--text' " 
                    v-text="item.title"
                    ></v-list-item-title>

                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <!-- List General Menu -->
            <v-list
            v-if="this.userDahUpdate == 'yes'"
            nav
            dense
            >
                <v-list-item
                v-for="(item, index) in menuMems"
                :key="index"
                :to="item.url"
                link
                @click="menuMainClick"
                class="btn-general-menu"
                >
                    <v-list-item-icon>
                        <!-- <v-badge
                        color="red"
                        content="6"
                        > -->
                        <v-icon v-text="item.icon"></v-icon>
                        <!-- </v-badge> -->
                    
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item>
            </v-list>

            <v-list
            v-if="this.userDahUpdate == 'no'"
            nav
            dense
            >
                <v-list-item
                to="/profile"
                link
                @click="menuMainClick"
                class="btn-general-menu"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    
                    </v-list-item-icon>
                    <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list
            nav
            dense
            >
                <v-list-item
                link
                class="btn-general-menu"
                @click="toggle_dark_mode"
                >
                    <v-list-item-icon>
                        <v-icon :class="valueText == 'Light Mode'? '' : 'primary--text'" v-text="valueIcon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title :class="valueText == 'Light Mode'? '' : 'primary--text text-bold'" v-text="valueText"></v-list-item-title>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>


            <!-- Tab List Admin -->
            <v-list
            v-if="onlyAdmin || onlyModerator || onlyHR || onlyInfra || onlyCarManager || onlyRoomManager && this.userDahUpdate == 'yes'"
            nav
            dense
            class="left-main-admin"
            >
            <!-- v-if="onlyAdmin || onlyModetatorAndManager" -->
                <v-list-item
                id="admin"
                @click="openListAdmin"
                link
                >
                    <v-list-item-icon>
                        <v-icon>mdi-account-wrench</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Administrator</v-list-item-title>
                </v-list-item>
                <v-expand-transition>
                    <v-list
                    v-show="expandAdmin"
                    nav
                    dense
                    class="pa-0"
                    >
                        <!-- <v-list-item
                        @click="clickOnListAdmin(item)"
                        v-for="(item, index) in menuAdmin"
                        :key="index"
                        class="child-admin"
                        :to="item.url"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item> -->
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyHR"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-announcement"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-bullhorn-variant</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Announcement</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyHR || onlyModerator"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-stafflist"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-group</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Staff List</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyHR"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-leave"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-calendar-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Leave</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyCarManager"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-carBooking"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-car-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Car Booking</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyRoomManager || onlyInfra"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-meetingRoom"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-forum</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Meeting Room</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item
                        v-if="onlyAdmin || onlyInfra"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-inventory"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-desktop-classic</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Inventory</v-list-item-title>
                        </v-list-item>
                        
                        <!-- <v-list-item
                        v-if="onlyAdmin"
                        @click="clickOnListAdmin()"
                        class="child-admin"
                        to="/systemAdmin-setting"
                        link
                        imageFormat
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Advance Setting</v-list-item-title>
                        </v-list-item> -->

                    </v-list>
                </v-expand-transition>


            </v-list>
            
            <!-- <v-divider></v-divider> -->

            <!-- Tab Logout -->
            <template v-slot:append>
                <v-list
                nav
                dense
                >
                    <v-list-item class="primary" link @click="signOut">
                        <v-list-item-icon>
                        <v-icon class="icon-white">mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="text-white text-bold">Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>




        <!-- Display Summary User -->
        <template>
        <div class="text-center">
            <v-dialog
            v-model="dialogSummaryProfile"
            width="344"
            height="500"
            >
                <v-card
                    class="mx-auto "
                    max-width="344"
                >
                    <!-- src="../assets/img/others/mobile-menu3.jpg" -->
                    <div
                    height="265px"
                    class="d-flex flex-column justify-center align-center pa-10 header-summaryCardProfile"
                    align="center"
                    >
                        <v-badge
                        bordered
                        overlap
                        offset-x="65"
                        offset-y="200"
                        class="custom-badge-btn"
                        >
                            <v-btn class="primary--text btn-cam-profile" color="white" fab small slot="badge" @click.native="editImageProfile">
                                <!-- <v-avatar size="80"> -->
                                    <!-- <v-img src="https://cdn.vuetifyjs.com/images/logos/v.png"></v-img> -->
                                    <v-icon dense >mdi-camera</v-icon>
                                <!-- </v-avatar> -->
                            </v-btn>
<!--  -->
                            <v-avatar size="250">
                                <v-img :src="this.profileImageUrl"></v-img>
                            </v-avatar>
                        </v-badge>
                        
                    </div>
                    
                    <!-- align="center" class="d-flex flex-column justify-center align-center" -->


                    <v-card-title  v-text="this.UserDisplay(this.$store.getters.user.name)"></v-card-title>

                    <v-card-subtitle v-if="this.$store.getters.user.position != null || this.$store.getters.user.position != ''" v-text="this.$store.getters.user.position">
                    </v-card-subtitle>
                    <v-card-subtitle v-if="this.$store.getters.user.position == null || this.$store.getters.user.position == ''">
                        (No Position Title)
                    </v-card-subtitle>

                    <v-card-text class="pb-0" style="display:flex;">
                        <v-container>
                            <v-row >
                                <p class="mr-5 mb-1"><v-icon class="icon-text" small>mdi-phone</v-icon><span v-text="this.$store.getters.user.phone"></span></p>
                            </v-row>
                            <v-row>
                                <p><v-icon class="icon-text" small>mdi-email</v-icon><span v-text="this.$store.getters.user.email1"></span></p>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="white"
                            class="primary"
                            icon
                            @click="goToPage('/profile');dialogSummaryProfile = false;"
                        >
                            <v-icon>mdi-account-details</v-icon>
                        </v-btn>
                        <!-- <v-btn
                            color="white"
                            class="primary"
                            icon
                            @click="editImageProfile"
                        >
                            <v-icon>mdi-camera</v-icon>
                        </v-btn> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        </template>

        <!-- Popup Edit Profile Image -->
        <template>
                <v-dialog
                v-model="dialogEditImage"
                persistent
                max-width="500px"
                >
                    <v-card>

                        <v-card-title class="title-bar-modul">
                            <span class="text-h5">Upload New Profile Image</span>
                        </v-card-title>

                        <v-card-text class="pb-0">

                            <span class="blackk--text">
                                <span class="red--text text-bold">**Note:</span><br>
                                 <span class="text-bold">IOS</span> capturing image is in HEIC format which is <b>not supported</b> by standard browser.
                                 We recommend to use standard picture format (PNG/JPEG).
                            </span>
                        
                            <v-image-input
                            class="mt-3"
                            id="uploadProfileImage"
                            v-model="viewImageProfile"
                            accept="image/png, image/jpeg, image/bmp"
                            :image-quality="0.85"
                            clearable
                            image-format="jpeg"
                            uploadIcon="mdi-camera"
                            fullWidth
                            @file-info="onFileInfo"
                            />
                        
                            <strong>
                                <span class="red--text">**</span>
                                Click on box above to upload new profile picture.
                            </strong>

                        </v-card-text>

                        

                        <v-card-actions class="pb-5">
                            <v-btn
                                :disabled="viewImageProfile == null"
                                class="primary icon-text"
                                @click="uploadImageProfile"
                            >
                                <v-icon>mdi-image-area-close</v-icon> Update
                            </v-btn>
                            <v-btn
                                class="primary icon-text"
                                @click="dialogEditImage = false; viewImageProfile = null;"
                            >
                                Cancel
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-dialog>
        </template>



        <!-- Success Upload -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccessUploadImg"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5" color="green">
                        <v-icon class="icon-text" color="success">mdi-check-circle</v-icon>
                        Success
                    </v-card-title>
                    <v-card-text class="blackk--text">Upload New Profile Image Success</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogEditImage = false; dialogSuccessUploadImg = false; viewImageProfile = null;"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Failed Success -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogFailedUploadImg"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5" color="green">
                        <v-icon class="icon-text" color="red">mdi-alert-circle</v-icon>
                        Failed
                    </v-card-title>
                    <v-card-text class="blackk--text">Upload New Profile Image Failed. Please try again or directly contact admin.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold"
                        color="primary"
                        text
                        @click="dialogFailedUploadImg = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>


        <!-- Dialog User Degil -->
        <template>
          <v-row justify="center">
            <v-dialog
              v-model="dialogReminderProfile"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar
                  dark
                  color="primary"
                >
                  <!-- <v-btn
                    icon
                    dark
                    @click="dialogReminderProfile = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                  <v-toolbar-title>
                      <!-- <v-icon>mdi-alert</v-icon> -->
                      REMINDER
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                    <!-- <v-btn
                    icon
                    dark
                    @click="dialogReminderProfile = false"
                    >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                </v-toolbar>
                <v-list
                  three-line
                  subheader
                >
                  <v-list-item>
                    <v-list-item-content>
                      <h1 class="h1 primary--text mt-2">
                          <v-icon color="primary" large>mdi-alert</v-icon>
                          GENTLY REMINDER
                      </h1>
                      <v-list-item-subtitle class="blackk--text mt-2">In order to start using MEMS functions, you need to update Profile Details, Profile Picture and Emergency Contact.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                
                <v-container class="ma-0">
                    <v-card-subtitle>
                        <v-icon color="primary" class="mr-2">mdi-information</v-icon>
                        Expand/Collapse the tab below and refer the slide for manual of update process.
                    </v-card-subtitle>
                    <v-expansion-panels class="ma-5">
                        <v-expansion-panel>

                            <v-expansion-panel-header>
                                <strong>
                                    <v-icon class="mr-2">mdi-account-circle</v-icon>
                                    Update Profile Picture
                                </strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-carousel
                                width="100"
                                height="500"
                                v-model="modelSlideEditProfileImg"
                                >
                                    <v-carousel-item
                                    v-for="(item, i) in remindProfileImg"
                                    :key="i"
                                    >
                                        <v-container
                                        fill-height
                                        fluid
                                        pa-0 ma-0 pb-0 
                                        >
                                            
                                            <v-layout fill-height align-end pb-0 mb-0>
                                                <v-flex xs12>
                                                    <v-img height="500" :src="item.picture"></v-img>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <strong>
                                    <v-icon class="mr-2">mdi-account-details</v-icon>
                                    Update Profile Details & Emergency Contact
                                </strong>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-carousel
                                width="100"
                                height="500"
                                v-model="modelSlideEditProfileDetails"
                                >
                                    <v-carousel-item
                                    v-for="(item, i) in remindProfileDetails"
                                    :key="i"
                                    >
                                        <v-container
                                        fill-height
                                        fluid
                                        pa-0 ma-0 pb-0 
                                        >
                                            
                                            <v-layout fill-height align-end pb-0 mb-0>
                                                <v-flex xs12>
                                                    <v-img height="500" :src="item.picture"></v-img>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-btn
                    color="primary"
                    class="ml-5"
                    @click="dialogReminderProfile = false"
                    >
                        Yes, Understand
                    </v-btn>
                </v-container>

              </v-card>
            </v-dialog>
          </v-row>
        </template>

    </v-container>


    

  
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';
import VImageInput from 'vuetify-image-input/a-la-carte';

export default {
    components: {
        'v-image-input': VImageInput,
    },
    data () {
      return {

        // userDahUpdate: null,
        
        dialogReminderProfile: false,
        remindProfileImg: [
            { picture: require("@/assets/img/caraUpdate/aa.png")},
            { picture: require("@/assets/img/caraUpdate/bb.png")},
            { picture: require("@/assets/img/caraUpdate/cc.png")},
            { picture: require("@/assets/img/caraUpdate/dd.png")},
            { picture: require("@/assets/img/caraUpdate/ee.png")},
            { picture: require("@/assets/img/caraUpdate/ff.png")},
            // { picture: require("@/assets/img/caraUpdate/g.png")},
        ],
        modelSlideEditProfileImg: false,
        remindProfileDetails: [
            { picture: require("@/assets/img/caraUpdate/11.png")},
            { picture: require("@/assets/img/caraUpdate/22.png")},
        ],
        modelSlideEditProfileDetails: false,

        imageFile: null,
        noTi: [
            { title:'Notification' , icon:"mdi-bell"},
        ],
        menuMems: [
            { title:'Announcement' , icon:"mdi-bullhorn", url: "/"},
            { title:'Dashboard' , icon:"mdi-view-dashboard-outline", url: "/dashboard"},
            { title:'Profile' , icon:"mdi-account", url: "/profile"},
            { title:'Leave' , icon:"mdi-calendar-cursor", url: "/leave"},
            { title:'Car Booking' , icon:"mdi-car", url: "/carBooking"},
            { title:'Meeting Room' , icon:"mdi-forum-outline", url: "/meetingRoom"},
            { title:'Asset Inventory' , icon:"mdi-laptop", url: "/assetInventory"},
        ],
        // settings: [
        //     { title:'Light Mode' , icon:"mdi-weather-sunny"},
        // ],
        valueIcon: "mdi-weather-sunny",
        valueText: "Light Mode",
        expandAdmin: false,
        menuAdmin: [
            { title:'Announcement' , icon:"mdi-bullhorn-variant", url: "/systemAdmin-announcement"},
            { title:'Staff List' , icon:"mdi-account-group", url: "/systemAdmin-stafflist"},
            { title:'Leave' , icon:"mdi-calendar-edit", url: "/systemAdmin-leave"},
            { title:'Car Booking' , icon:"mdi-car-cog", url: "/systemAdmin-carBooking"},
            { title:'Meeting Room' , icon:"mdi-forum", url: "/systemAdmin-meetingRoom"},
            { title:'Inventory' , icon:"mdi-desktop-classic", url: "/systemAdmin-inventory"},
            // { title:'Setting' , icon:"mdi-account-cog", url: "systemAdmin-setting"},
        ],
        drawer: null,
        mini: false,
        navbar: true,

        dialogSummaryProfile: false,
        dialogEditImage: false,

        urlImage: "",
        viewImageProfile: null,
        detailsDataImage: null,

        profileImageUrl: null,
        dialogSuccessUploadImg: false,
        dialogFailedUploadImg: false,

        blankProfile: "https://apis.spatialworks.com.my/publicdir/2022/01/20/noprofil.png",
        userloginas: "",

        myName: '',
        myPosition: ''
      }
    },
    methods: {

        toggle_dark_mode(){
            // console.log("test");
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

            if(this.valueIcon == "mdi-weather-sunny" && this.valueText == "Light Mode"){
                this.valueIcon = "mdi-weather-night";
                this.valueText = "Night Mode";
                // let element = document.querySelectorAll(".title-bar-modul")
                // element.forEach(function(res){
                //     res.classList.add("darkMode");
                // });

                // console.log(this.expandAdmin);

                

                let myInterval = setInterval(() => {

                    let btnAdmin = document.querySelector("#admin");
                    let listChildAdmin = document.querySelectorAll(".child-admin");
                    if (this.expandAdmin == true) {
                        btnAdmin.classList.add("admin-active");
                    }
                    else{
                        listChildAdmin.forEach(function(res){

                            console.log(res);

                            if (res.classList.contains('v-list-item--active') == true) {
                                btnAdmin.classList.remove("admin-active")
                                btnAdmin.classList.add("v-list-item--active")
                            }
                            else{
                                btnAdmin.classList.remove("admin-active")
                            }
                        })
                    }
                    
                    clearInterval(myInterval);
                }, 500);
            }
            else if(this.valueIcon == "mdi-weather-night" && this.valueText == "Night Mode"){
                this.valueIcon = "mdi-weather-sunny";
                this.valueText = "Light Mode";
                // let element = document.querySelectorAll(".title-bar-modul")
                // element.forEach(function(res){
                //     res.classList.remove("darkMode");
                // });

                let myInterval = setInterval(() => {

                    let btnAdmin = document.querySelector("#admin");
                    let listChildAdmin = document.querySelectorAll(".child-admin");
                    if (this.expandAdmin == true) {
                        btnAdmin.classList.add("admin-active"); 
                    }
                    else{
                        listChildAdmin.forEach(function(res){

                            if (res.classList.contains('v-list-item--active') == true) {
                                btnAdmin.classList.remove("admin-active")
                                btnAdmin.classList.add("v-list-item--active")
                            }
                            else{
                                btnAdmin.classList.remove("admin-active")
                            }
                        }) 
                    }
                    
                    clearInterval(myInterval);
                }, 500);
            }

            window.focusNotify = 0;
            
        },

        loadProfileImage(){

            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=3&id='+this.$store.getters.user.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                // console.log(this.$store.getters.user.idEmp);

                // Condition for first time user with total null data
                if (response.data.info == null || response.data.info == "") {
                    this.profileImageUrl = this.blankProfile;
                }
                else{
                    if (response.data.info.picture == "" || response.data.info.picture == null) {
                        this.profileImageUrl = this.blankProfile;
                    }
                    else{
                        this.profileImageUrl = response.data.info.picture; 
                    }
                }

                


            })
            .catch(error => {
                console.log(error.response);
                if (error.response.status === 401) {
                    this.signOut();
                }
                else if (error.response.status === 500) {
                    this.signOut();
                }

            })

            // let myInterval = setInterval(() => {
            //     this.profileImageUrl = this.urlImage;
            //     clearInterval(myInterval);
            // }, 1000);

        },

        loadMySummaryDetails(){

            this.myName = '';

            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);

                let data = response.data;

                for(let i in data){
                    if (data[i].idemp == this.$store.getters.user.idEmp) {
                        this.myName = data[i].name;
                    }
                }

            })
            .catch(error => { //eslint-disable-line
                console.log(error.response);
                
                if (error.response.status === 401) {
                    this.signOut();
                }
                else if (error.response.status === 500) {
                    this.signOut();
                }
            })

        },
        
        signOut() {
            this.$store.dispatch('signOut');
            this.$vuetify.theme.dark = false;
            window.focusNotify = 0;
        },

        openListAdmin(){
            this.expandAdmin = !this.expandAdmin
            // console.log(this.expandAdmin);
            let btnAdmin = document.querySelector("#admin");
            let listChildAdmin = document.querySelectorAll(".child-admin");
            if (this.expandAdmin == true) {
                btnAdmin.classList.add("admin-active")

                this.mouseover(false);
                this.mouseleave(false);
            }
            else{
                listChildAdmin.forEach(function(res){

                    if (res.classList.contains('v-list-item--active') == true) {
                        btnAdmin.classList.remove("admin-active")
                        btnAdmin.classList.add("v-list-item--active")
                    }
                    else{
                        btnAdmin.classList.remove("admin-active")
                    }
                })

                this.mouseover(false);
                this.mouseleave(true);
                
            }

            window.focusNotify = 0;
        },

        menuMainClick(){
            let btnAdmin = document.querySelector("#admin");
            // console.log(btnAdmin);

            if (btnAdmin != null && btnAdmin != ""){
                // console.log("testt");
                btnAdmin.classList.remove("v-list-item--active");
                let listChildAdmin = document.querySelectorAll(".child-admin");
                listChildAdmin.forEach(function(res){
                    res.classList.remove("stay-active")
                })
            }

            window.focusNotify = 0;
        },

        clickOnListAdmin(){
            let btnAdmin = document.querySelector("#admin");
            btnAdmin.classList.add("v-list-item--active")

            let listChildAdmin = document.querySelectorAll(".child-admin");
            listChildAdmin.forEach(function(res){
                res.classList.add("stay-active")
            })

            window.focusNotify = 0;
        },

        openSummaryProfile(){
            this.dialogSummaryProfile = true;
            window.focusNotify = 0;
            this.loadNotifyCar();
        },

        mouseover: function(){
            // if (this.expandAdmin == true) {
            //     this.mini = false;
            // }
            // else{
            //     this.mini = false;
            // }
        },

        mouseleave: function(){
            // if (this.expandAdmin == true) {
            //     this.mini = false;
            // }
            // else{
            //     this.mini = true;
            // }
        },

        editImageProfile(){
            this.dialogEditImage = true;
        },

        onFileInfo(image){
            // console.log(image);
            this.detailsDataImage = image;
        },

        uploadImageProfile(){

            // console.log(this.viewImageProfile);

            const dataURLtoFile = (dataurl, filename) => {
                const arr = dataurl.split(',')
                const mime = arr[0].match(/:(.*?);/)[1]
                const bstr = window.atob(arr[1])
                let n = bstr.length
                const u8arr = new Uint8Array(n)
                while (n) {
                    u8arr[n-1] = bstr.charCodeAt(n-1)
                    n -= 1 // to make eslint happy
                }
                return new File([u8arr], filename, { type: mime })
            }

            // generate file from base64 string
            const file = dataURLtoFile(this.viewImageProfile.toString(),this.detailsDataImage.name)
            // put file into form data
            const data = new FormData()
            data.append('files', file, file.name)

            axios.post(
                process.env.VUE_APP_API_URL + "employees/upload?profilpic=1",
                data,
                {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.getters.user.token,
                        "Content-Type": "multipart/form-data",
                    }
                }
            )
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.loadProfileImage();
                this.dialogSuccessUploadImg = true;
                this.checkUserDegil();
            })
            .catch(e => {
                console.log(e.response);
                this.dialogFailedUploadImg = true;
            })
        },

        checkUserDegil(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                // console.log(this.$store.getters.user);

                for(let i in data){
                    
                    if (data[i].idemp == this.$store.getters.user.idEmp) {

                        // console.log(data[i]);

                        if (data[i].phone == "") {
                            this.userDahUpdate = "no";
                            this.goToPage("/profile");
                            this.dialogReminderProfile = true;
                        }
                        else if (data[i].phone == null) {
                            this.userDahUpdate = "no";
                            this.goToPage("/profile");
                            this.dialogReminderProfile = true;
                        }
                        else{
                            if (data[i].picture == "") {
                                this.userDahUpdate = "no";
                                this.goToPage("/profile");
                                this.dialogReminderProfile = true;
                            }
                            else if (data[i].picture == null) {
                                this.userDahUpdate = "no";
                                this.goToPage("/profile");
                                this.dialogReminderProfile = true;
                            }
                            else{
                                this.userDahUpdate = "yes";
                                this.dialogReminderProfile = false;
                            }
                            
                        }
                    }

                }
                
            })
            .catch(error => {
                // console.log(error);
                console.log(error.response);
            })
        }
        
    },
    mounted(){ 

        this.loadNotifyLeave();
        this.loadNotifyCar();
        this.loadNotifyRoom();

        // this.$forceUpdate(this.loadNotifyLeave());
        // this.$forceUpdate(this.loadNotifyCar());
        // this.$forceUpdate(this.loadNotifyRoom());
        this.loadProfileImage();
        this.loadMySummaryDetails();
        
        let btnAdmin = document.querySelector("#admin");
        let listChildAdmin = document.querySelectorAll(".child-admin");
        listChildAdmin.forEach(function(res){

            if (res.classList.contains('v-list-item--active') == true) {
                btnAdmin.classList.add("v-list-item--active")
                res.classList.add("stay-active")
            }
        })

        this.mouseover(false)
        this.mouseleave(true)


        // console.log(this.$store.getters.user);


        // Paksa staff xreti bahasa Update profile..
        this.checkUserDegil();

        

        // this.urlImage = "../assets/img/profile/Salah.jpg"
        // console.log(this.capitalizeWords(this.$store.getters.user.name));
    },
    computed: {
        countNotify(){
            
            let count = null;
            let leave = null;
            let car = null;
            let meetingRoom = null;

            if (this.totalPendingLeave != null) {
                leave = this.totalPendingLeave;
            }
            else{
                leave = 0;
            }

            if (this.totalPendingCar != null) {
                car = this.totalPendingCar;
            }
            else{
                car = 0;
            }

            if (this.totalPendingMeetingRoom != null) {
                meetingRoom = this.totalPendingMeetingRoom;
            }
            else{
                meetingRoom = 0;
            }

            
            
            // console.log(count);

            if (count != 0) {
                if (this.$store.getters.userRole === 'ADMIN') {
                    count = leave + car + meetingRoom;
                    if (count > 0) {
                        return count;
                    }
                    else{
                        return null;
                    } 
                }
                if (this.$store.getters.userRole === 'NORMAL USER') {
                    if (this.$store.getters.user.kuli === true) {
                        if (leave > 0) {
                            count = leave;
                            return count;
                        }
                        else{
                            return null;
                        } 
                    }
                    else{
                        return null;
                    }
                }
                if (this.$store.getters.userRole === 'MODERATOR') {
                    if (this.$store.getters.user.kuli === true) {
                        if (leave > 0) {
                            count = leave;
                            return count;
                        }
                        else{
                            return null;
                        } 
                    }
                    else{
                        return null;
                    }
                    // if (leave > 0) {
                    //     count = leave;
                    //     return count;
                    // }
                    // else{
                    //     return null;
                    // } 
                }
                if (this.$store.getters.userRole === 'CAR MANAGER') {
                    if (car > 0) {
                        count = car;
                        return count;
                    }
                    else{
                        return null;
                    } 
                }
                if (this.$store.getters.userRole === 'MEETING ROOM') {
                    if (meetingRoom > 0) {
                        count = meetingRoom;
                        return count;
                    }
                    else{
                        return null;
                    } 
                }
                else{
                    return null;
                }
            }
            else{
                return null;
            }
            
        },

        // For testing
        person1(){
            return this.$store.getters.user.idEmp === 'FT175' ? true : false
        },
    },
    created (){
        bus.$on('changeIt', (data) => {
            
            // console.log(data);

            if (data == "loadNotify") {
                this.loadNotifyLeave();
                this.loadNotifyCar();
                this.loadNotifyRoom();
            }
            else if (data == "openDP") {
                this.dialogSummaryProfile = true;
            }
            else if (data == "loadProfile") {
                this.loadMySummaryDetails();
                this.checkUserDegil();
            }

           
        })
    }
}
</script>


<style>

    .left-panel-content{
        width: 56px !important;
        margin: 0px !important;
    }

    .left-panel-content:hover{
        width: 256px !important;
        margin: 0px !important;
    }

    .header-left-panel{
        background: url('../assets/img/others/mobile-menu3.jpg') !important;
        background-size: cover !important;
        color: white !important;
    }

    .header-summaryCardProfile{
        background: url('../assets/img/others/mobile-menu3.jpg') !important;
    }

    /* .left-main-admin{
        background-image: linear-gradient(to left, #65989E, #ffffff) !important;
    } */
    
    /* .child-admin{
        background: grey !important;
    } */

    .admin-active,
    .v-list-item.v-list-item--link.theme--light.admin-active,
    .v-list-item.v-list-item--link.theme--dark.admin-active{
        /* background-image: linear-gradient(to top, #07476f, #65989E) !important; */
        /* background-image: linear-gradient(to top, #000d1d, #06466E) !important; */
        background-image: linear-gradient(to top, #021c33, #136499) !important;
        color: white !important;
        font-weight: bold !important;
    }
    .admin-active > div > i{
        color: white !important;
    }
    .admin-active > div{
        color: white !important;
        font-weight: bold !important;
    }


    /* Background Main */
    /* .main-cust > .v-main__wrap{
        background-image: url("~@/assets/img/others/m.jpg") !important;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    } */
    
    

</style>